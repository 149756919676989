const businessTypes = [
  "Mid-Size Business",
  "Small Business",
  "Startup",
  "Micro Business",
  "Idea",
  // Add more business types here
];

export default businessTypes;
